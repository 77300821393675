<template>
  <button :class="{ btn: true, disabled: disabled, primary: variant == 'primary' }" :disabled="disabled">
    <div v-if="iconLeft" class="icon-left mr-2">
      <ui-icon :icon="iconLeft" />
    </div>
    <slot />
    <div v-if="iconRight && !loading" class="icon-right ml-2">
      <ui-icon :icon="iconRight" />
    </div>
    <div v-if="icon && !loading" class="icon ml-2">
      <ui-icon :icon="icon" />
    </div>
    <div v-if="loading" class="icon-right ml-2">
      <ui-progress-circular :size="20" class="spinner" :width="2" indeterminate />
    </div>
  </button>
</template>

<script setup lang="ts">
import { defineProps } from 'vue'

const viewTransitionName = ref(`btn-${Math.random().toString(36).substring(7)}`)

const { disabled } = defineProps<{
  disabled?: boolean,
  iconLeft?: string,
  iconRight?: string,
  icon?: string,
  variant?: 'icon' | 'primary' | 'secondary'
  loading?: boolean
}>()
</script>

<style scoped lang="scss">
.btn {
  border-radius: 30px;
  font-weight: 600;
  border: 0px;
  padding: 8px 20px;
  // layout: contain;
  view-transition-name: ref("viewTransitionName");
  height: 40px;
  display: inline-flex;
  align-items: center;
  justify-content: center;

  transition: transform .2s ease, opacity .2s ease, background-color .2s ease, color .2s ease;

  &:active {
    transform: scale(.9);
  }

  &.disabled {
    opacity: .6;
    pointer-events: none;
  }

  @media (max-width: 800px) {
    font-size: 1em;
  }

  --webkit-tap-highlight-color: transparent;
  --webkit-appearance: none;

  background-color: rgb(var(--background-color));

  &.primary {
    background-color: rgb(var(--primary-color));
    color: #fff;
  }

  .icon {
    width: 30px;
  }

  .icon-left,
  .icon-right {
    width: 30px;
  }
}
</style>