<template>
  <span
    :class="_classStringToObject(`mdi ${icon}` + ' span-2fnk003dqad')"
  ></span>
</template>

<script setup lang="ts">
export interface IconProps {
  icon: string;
}

const props = defineProps<IconProps>();

function _classStringToObject(str: string) {
  const obj: Record<string, boolean> = {};
  if (typeof str !== "string") {
    return obj;
  }
  const classNames = str.trim().split(/\s+/);
  for (const name of classNames) {
    obj[name] = true;
  }
  return obj;
}
</script>

<style scoped>
.span-2fnk003dqad {
  font-size: 1.5em;
}
</style>